import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Input,
  OnChanges
} from "@angular/core";
import {
  PadFactoryService,
  iChangeInterface
} from "../services/pad-factory/pad-factory.service";
import { debounceTime } from "rxjs/operators";
import { CONTEXT } from "../services/constants";

declare const CodeMirror: any;

@Component({
  selector: "codemirror",
  templateUrl: "./codemirror.component.html",
  styleUrls: ["./codemirror.component.css"]
})
export class CodemirrorComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("codearea")
  codearea: ElementRef;
  @Input()
  showLine: boolean;
  @Input()
  language: string;

  editor: any;

  constructor(public padFactory: PadFactoryService) {
    this.padFactory.changes$
      .pipe(debounceTime(20))
      .subscribe(this.onPadChanges.bind(this));
  }

  ngOnChanges() {
    this.setTemplate();
  }

  ngAfterViewInit() {
    this.editor = CodeMirror.fromTextArea(this.codearea.nativeElement, {
      lineNumbers: this.showLine || false,
      styleActiveLine: true,
      matchBrackets: true,
      theme: "cobalt"
    });

    this.setTemplate();

    this.editor.on("change", () => {
      this.padFactory.updateSnippet(this.editor.getValue());
    });
  }

  onPadChanges(changes: iChangeInterface) {
    console.log("....");
    this.editor.setValue(
      changes.activePad.codeSnippet || this.getTemplate(changes.activePad.key)
    );
  }

  setTemplate() {
    this.editor && this.editor.setValue(this.getTemplate());
    this.editor && this.padFactory.updateSnippet(this.editor.getValue());
  }

  getTemplate(key?) {
    let template: string = CONTEXT.LANGUAGES[this.language].template;
    if (this.language === "Java") {
      return template.replace("Pad", "Pad" + (key || 1));
    }
    return template;
  }

  ngOnInit() {}
}
