import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CONTEXT } from "../constants";

@Injectable({
  providedIn: "root"
})
export class CompilerService {
  constructor(private http: HttpClient) {}

  run(content: string, key: Number, lan: string) {
    let name = "Pad" + key + CONTEXT.LANGUAGES[lan].extn;
    lan = (CONTEXT.LANGUAGES[lan].code || lan).toLowerCase();
    let params = { files: [{ name, content }], stdin: "", command: "" };
    return this.http.post(`/run/${lan}?version=latest`, params);
  }
}
