import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodemirrorComponent } from './codemirror/codemirror.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularSplitModule } from '../vendor/angular-split/modules/angularSplit.module';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    CommonModule,
    AngularSplitModule,
    NgbDropdownModule,
    HttpClientModule
  ],
  declarations: [DashboardComponent, CodemirrorComponent],
  exports: [DashboardComponent]
})
export class JspadModule { }
