export class PadModel {
  codeSnippet: string;
  key: number;
  active: boolean;
  
  constructor(key: number, codeSnippet?: string, active?: boolean) {
    this.active = active;
    this.key = key;
    this.codeSnippet = codeSnippet;
  }
}


