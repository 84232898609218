import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PadModel } from "./pad.model";

export interface  iChangeInterface{
  "activePad": PadModel,
  "pads": PadModel[],
  "action"?: string
}

@Injectable({
  providedIn: "root"
})
export class PadFactoryService {

  private pads: PadModel[] = [];
  public changes$: Subject<iChangeInterface> = new Subject();

  constructor() {
    this.pads.push(new PadModel(1, "", true));
  }

  getPads(): PadModel[] {
    return this.pads;
  }

  addPad() {
    let key = this.getLastpad(this.pads).key + 1;
    this.pads.push(new PadModel(key));
    this.selectPad(key);
  }

  getLastpad(pads): PadModel {
    return pads[pads.length - 1];
  }

  selectPad(key: number) {
    this.pads.forEach(pad => {
      pad.active = key === pad.key;
    });
    this.notify("select");
  }

  getActivePad(): PadModel {
    return this.pads.find(pad => pad.active);
  }

  deletePad(key: number) {
    let activePad = this.getActivePad();
    this.pads = this.pads.reduce((mem, pad) => {
      if (pad.key !== key) {
        mem.push(pad);
      } else if (activePad.key === pad.key) {
        this.getLastpad(mem).active = true;
      }
      return mem;
    }, []);
    this.notify("delete");
  }

  notify(action) {
    this.changes$.next({ "activePad": this.getActivePad(), "pads": this.pads, action });
  }

  updateSnippet(snippet: string) {
    this.getActivePad().codeSnippet = snippet;
  }
}
