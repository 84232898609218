import { Component, OnInit } from "@angular/core";
import { PadModel } from "../services/pad-factory/pad.model";
import { CONTEXT } from "../services/constants";
import { debounceTime } from "rxjs/operators";
import { CompilerService } from "../services/compiler/compiler.service";
import { bind } from "@angular/core/src/render3/instructions";
import {
  PadFactoryService,
  iChangeInterface
} from "../services/pad-factory/pad-factory.service";


@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  plusLocation: string;
  pads: PadModel[];
  fontSize: string = "1em";
  languages: string[] = Object.keys(CONTEXT.LANGUAGES);
  language: string = this.languages[0];
  running: boolean = false;
  runResult: any = {};

  constructor(
    public padFactory: PadFactoryService,
    private compiler: CompilerService
  ) {
    this.padFactory.changes$
      .pipe(debounceTime(20))
      .subscribe(this.onPadChanges.bind(this));
  }

  ngOnInit() {
    this.pads = this.padFactory.getPads();
  }

  selecetPad(pad: PadModel) {
    this.padFactory.selectPad(pad.key);
  }

  deletePad(pad: PadModel) {
    this.padFactory.deletePad(pad.key);
  }

  addPad() {
    this.padFactory.addPad();
  }

  onPadChanges(changes: iChangeInterface) {
    console.log(changes);
    this.pads = changes.pads;
    this.plusLocation = this.pads.length * 102 + 173 + "px";
  }

  onFontSliderChange({ target: { value } }) {
    this.fontSize = (value < 5 ? value / 5 + 0.1 : value / 10 - 0.5 + 1) + "em";
    console.log(this.fontSize);
  }

  onLanguage(language) {
    this.language = language;
    this.runResult = {};
  }

  run() {
    this.runResult = {};
    this.running = true;
    let {codeSnippet , key} = this.padFactory.getActivePad()
    this.compiler
      .run(codeSnippet, key, this.language)
      .subscribe((data: {stdout: string, stderr: string}) => {
        this.running = false;
        this.runResult = data;
      });
  }
}
