export const CONTEXT = {
  LANGUAGES: {
    JavaScript: {
      extn: ".js",
      template: '\n\nconsole.log("Hello world");'
    },
    TypeScript: { 
      extn: ".ts", 
      template: 
`\n\nconst hello : string = "Hello World!";
console.log(hello);`
    },
    Java: { 
      extn: ".java",
      template: 
`\n\nclass Pad {
  public static void main(String[] args) {
    System.out.println("Hello World!");
  }
}`
    },
    "C#": {
      extn: ".cs", 
      code: "csharp",
      template:
`\n
using System;

class MainClass {
    static void Main() {
        Console.WriteLine("Hello World!");
    }
}
`
    },
    C: { 
      extn: ".c",
      template:
`\n
#include <stdio.h>

int main(void) {
    printf("Hello World!");
    return 0;
}`
    },
    "C++": { extn: ".cpp", code: "cpp",
    template:
`\n#include <iostream>
using namespace std;

int main() {
    cout << "Hello World!";
    return 0;
}`
},
    PHP: { extn: ".php", 
    template: 
`\n<?php

echo "Hello World";` 
},
    Perl: { extn: ".pl", template:`\nprint "Hello World!";`},
    Swift: { extn: ".swift", template:`\nprint("Hello World!")` },
    Scala: { extn: ".scala", template:
`\nobject Main extends App {
  println("Hello World!")
}`},
    Python: { extn: ".py" ,  template:`\nprint("Hello World!")`}
  }
};
